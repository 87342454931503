<template>
  <client-only>
    <div
      :id="htmlId"
      :key="state.ui.refreshKey"
      class="top-0 right-0 z-[9999] fixed bg-gray-700 px-md py-sm rounded shadow-md shadow-black transition-all"
      :class="state.ui.isExpanded ? 'h-[100vh] w-full' : 'h-auto'"
    >
      <div
        v-show="state.ui.isExpanded && state.ui.errors.length === 0"
        class="flex flex-col h-full"
      >
        <div class="relative flex flex-row space-x-sm">
          <button
            v-for="(tab, index) of tabs"
            :key="index"
            class="transition-all rounded-t-xl px-sm h-md shrink"
            :class="
              state.ui.showTabIndex === index
                ? 'bg-blue-600 text-white shadow-black shadow-lg'
                : 'bg-vdv-grey'
            "
            @click="toggleTab(index)"
          >
            {{ tab }}
          </button>
          <button
            class="absolute top-0 right-0 ml-auto text-center text-white h-md w-md pl-sm"
            @click="toggleShowSeoTool"
          >
            <FaIcon icon-class="fas fa-xmark" classes="fa-2x fa-fw" />
          </button>
        </div>
        <!-- __________________Tabs__________________-->
        <div
          v-show="tabs[state.ui.showTabIndex] === 'Inhalt'"
          class="vdv-analysis__tab"
        >
          <div class="absolute top-2xs right-2xs" style="color: #ffffffa0">
            Timestamp: {{ state.generalSeoAnalysis?.date }}
          </div>

          <button
            class="w-full text-white text-xl transition-all bg-blue-600 shadow-sm shadow-black h-2xl px-md py-sm flex-[1_0_auto]"
            :disabled="state.ui.isLoading"
            :class="
              state.ui.isLoading
                ? 'hover:none !bg-sign-yellow'
                : 'hover:bg-sign-green'
            "
            @click="getSeoContentAnalysis"
          >
            <span v-show="!state.ui.isLoading"
              >&#8921; Analyse starten &#8920;
            </span>
            <span v-show="state.ui.isLoading">
              <span>Seite wird analysiert...<br /></span
              ><FaIcon icon-class="fas fa-circle-notch fa-spin"
            /></span>
          </button>
          <div class="mt-md">
            <AnalysisData
              v-if="state.generalSeoAnalysis?.analysisData?.data"
              :content-analysis="state.generalSeoAnalysis.analysisData.data"
            />
          </div>
        </div>
        <div
          v-show="tabs[state.ui.showTabIndex] === 'Lesbarkeit'"
          class="vdv-analysis__tab"
        >
          <div class="absolute top-2xs right-2xs" style="color: #ffffffa0">
            Timestamp: {{ state.readabilityAnalysis?.date }}
          </div>
          <button
            class="w-full text-white text-xl transition-all bg-blue-600 shadow-sm shadow-black h-2xl px-md py-sm flex-[1_0_auto]"
            :disabled="state.ui.isLoading"
            :class="
              state.ui.isLoading
                ? 'hover:none !bg-sign-yellow'
                : 'hover:bg-sign-green'
            "
            @click="getReadabilityAnalysis"
          >
            <span v-show="!state.ui.isLoading"
              >&#8921; Readability-Analyse starten &#8920;
            </span>
            <span v-show="state.ui.isLoading">
              <span>Seite wird analysiert...<br /></span
              ><FaIcon icon-class="fas fa-circle-notch fa-spin"
            /></span>
          </button>
          <div class="mt-md">
            <ReadabilityAnalysisData
              v-if="state.readabilityAnalysis?.analysisData?.data"
              :readbility-analysis="
                state.readabilityAnalysis.analysisData.data.data
              "
            />
          </div>
        </div>
        <div
          v-show="tabs[state.ui.showTabIndex] === 'Google-Preview'"
          class="vdv-analysis__tab"
        >
          <GoogleResultPreview
            v-if="Object.keys(state.googlePreview).length > 0"
            :data="state.googlePreview"
          />
        </div>
        <div
          v-show="tabs[state.ui.showTabIndex] === 'EXAMPLE'"
          class="vdv-analysis__tab"
        >
          <div class="mt-md">EXAMPLE</div>
        </div>
      </div>
      <div v-show="!state.ui.isExpanded && state.ui.errors.length === 0">
        <button
          class="ml-auto text-2xl text-center text-white px-sm"
          @click="toggleShowSeoTool"
        >
          <FaIcon icon-class="fas fa-analytics" />
        </button>
      </div>
      <div v-show="state.ui.errors.length > 0">
        <div class="text-white">SEO-Tool nicht nuztbar:</div>
        <div
          v-for="error in state.ui.errors"
          :key="error"
          class="text-lg font-bold text-red-300"
        >
          <span v-if="error === 'keywordMissing'"
            ><FaIcon
              icon-class="fas fa-exclamation-circle"
              class="text-sign-red mr-xs"
            />Bitte Keyword hinterlegen</span
          >
          <span v-if="error === 'analysisFetchError'"
            ><FaIcon
              icon-class="fas fa-exclamation-circle"
              class="text-sign-red mr-xs"
            />Fehler beim Abfragen der Analyse, bitte Seite neu laden</span
          >
        </div>
      </div>
    </div>
  </client-only>
</template>

<script setup lang="ts">
import type {
  ContentResponseModel,
  GooglePreview,
  ReadabilityResponseModel,
} from '@/@types/seoreviews';
import AnalysisData from './general/analysis-data.vue';
import ReadabilityAnalysisData from './readability/analysis-data.vue';
import GoogleResultPreview from './previews/google-result-preview.vue';
import FaIcon from '@/components/fa-icon.vue';
const props = defineProps({
  metaData: {
    type: Object as PropType<{
      title: string;
      meta: { name: string; content: string }[];
      breadcrumbs: { name: string; item: string }[];
    }>,
    required: true,
  },
});

const router = useRouter();
const htmlId = 'vdv-seo-analysis';
const pageData = usePageData();
const tabs = ['Inhalt', 'Google-Preview', 'Lesbarkeit'];
const site = useSiteIdent();

const localStorageKeys = {
  generalSeoAnalysis: router.currentRoute?.value?.path + '__lastSeoAnalysis__',
  readabilityAnalysis:
    router.currentRoute?.value?.path + '__lastReadabilityAnalysis__',
};

/**
 * Should probably put analysis-Data in its own prop
 */
const state = reactive({
  generalSeoAnalysis: undefined as SeoContentData,
  googlePreview: undefined as GooglePreview,
  readabilityAnalysis: undefined as ReadabilityContentData,
  keywordSuggestions: undefined as any,
  linkAnalysis: undefined as any,
  //---
  siteMeta: {
    keyword: '',
    relatedKeywords: [] as string[],
  },
  //---
  ui: {
    showTabIndex: 0,
    errors: [] as string[],
    isLoading: false,
    isExpanded: false,
    refreshKey: 0,
  },
});

onMounted(async () => {
  const siteKeywords = getKeywords();
  state.siteMeta.keyword = siteKeywords.main;
  state.siteMeta.relatedKeywords = siteKeywords.related;

  if (!state.siteMeta.keyword) {
    state.ui.errors.push('keywordMissing');
  }

  state.googlePreview = getGooglePreviewData();
  state.generalSeoAnalysis = loadFromCache().generalSeoAnalysis();
  state.readabilityAnalysis = loadFromCache().readabilityAnalysis();
});

function loadFromCache() {
  const generalSeoAnalysis = () => {
    const generalSeoAnalysisData = window.localStorage.getItem(
      localStorageKeys.generalSeoAnalysis,
    );
    if (generalSeoAnalysisData) {
      const seoDataParsed = JSON.parse(
        generalSeoAnalysisData,
      ) as SeoContentData;
      if (seoDataParsed.date) {
        const analysisDate = new Date(seoDataParsed.date);
        analysisDate.setDate(analysisDate.getDate() + 2);
        if (analysisDate < new Date()) {
          window.localStorage.removeItem(localStorageKeys.generalSeoAnalysis);
          return undefined;
        } else {
          return seoDataParsed;
        }
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };
  const readabilityAnalysis = () => {
    const readabilityAnalysis = window.localStorage.getItem(
      localStorageKeys.readabilityAnalysis,
    );
    if (readabilityAnalysis) {
      const readabilityDataParsed = JSON.parse(
        readabilityAnalysis,
      ) as ReadabilityContentData;
      if (readabilityDataParsed.date) {
        const analysisDate = new Date(readabilityDataParsed.date);
        analysisDate.setDate(analysisDate.getDate() + 2);
        if (analysisDate < new Date()) {
          window.localStorage.removeItem(localStorageKeys.readabilityAnalysis);
          return undefined;
        } else {
          return readabilityDataParsed;
        }
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };
  return { generalSeoAnalysis, readabilityAnalysis };
}

function toggleShowSeoTool() {
  state.ui.isExpanded = !state.ui.isExpanded;
}

function toggleTab(index: number) {
  state.ui.showTabIndex = index;
}

function getKeywords(): { main: string; related: string[] } {
  const keywordObj = { main: '', related: [] as string[] };
  const keywords =
    pageData.value.seoMetaData?.length > 0
      ? pageData.value.seoMetaData[0].keywords
      : null;
  if (keywords?.length > 0) {
    keywordObj.main = keywords[0].keyword;
    if (keywords.length > 1) {
      keywordObj.related = keywords.slice(1).map((x) => x.keyword);
    }
  }
  return keywordObj;
}

function getGooglePreviewData(): GooglePreview {
  const googlePreview: GooglePreview = {
    title: props.metaData.title,
    description: props.metaData.meta.find((x) => x.name === 'description')
      ?.content,
    breadcrumbs: props.metaData.breadcrumbs.map((x) => x.item),
    url: props.metaData.meta.find((x) => x.name === 'og:url')?.content,
    keyword: state.siteMeta.keyword,
    relatedKeywords: state.siteMeta.relatedKeywords,
  };
  return googlePreview;
}

async function getSeoContentAnalysis() {
  state.ui.isLoading = true;

  //Remove SEO-Analysis HTML from cloned Document as it will break analysis results like Keywords
  const documentClone = document.cloneNode(true) as Document;
  documentClone.getElementById(htmlId).innerHTML = '';

  const seoAnalysisResult = await useFetch(
    `/api/${site}/seo/content/seoAnalysis`,
    {
      method: 'POST',
      body: {
        html: `<html>${documentClone.body.parentElement.innerHTML}</html>`,
        keyword: state.siteMeta.keyword,
        relatedKeywords: state.siteMeta.relatedKeywords.join('|'),
      },
    },
  );

  if (seoAnalysisResult.data.value.status === 'error') {
    state.ui.errors.push('analysisFetchError');
  } else {
    const seoGeneralAnalysisObject = {
      date: new Date().toISOString(),
      analysisData: seoAnalysisResult.data.value,
    };
    state.generalSeoAnalysis = seoGeneralAnalysisObject;
    localStorage.setItem(
      localStorageKeys.generalSeoAnalysis,
      JSON.stringify(seoGeneralAnalysisObject),
    );
  }
  state.ui.refreshKey += 1;
  state.ui.isLoading = false;
}

async function getReadabilityAnalysis() {
  state.ui.isLoading = true;

  const thisPageUrl = props.metaData.meta
    .find((x) => x.name === 'og:url')
    .content.concat('&disable-seo-tool=true');

  const seoReadabilityResult = await useFetch(
    `/api/${site}/seo/content/seoReadability`,
    {
      method: 'POST',
      body: { url: thisPageUrl },
    },
  );

  if (seoReadabilityResult.data.value.status === 'error') {
    state.ui.errors.push('analysisFetchError');
  } else {
    const readabilityAnalysisObject = {
      date: new Date().toISOString(),
      analysisData: seoReadabilityResult.data.value,
    };
    state.readabilityAnalysis = readabilityAnalysisObject;
    localStorage.setItem(
      localStorageKeys.readabilityAnalysis,
      JSON.stringify(readabilityAnalysisObject),
    );
  }

  state.ui.refreshKey += 1;
  state.ui.isLoading = false;
}

type SeoContentData = {
  date: string | null;
  analysisData: { data: ContentResponseModel; status: string };
};

type ReadabilityContentData = {
  date: string | null;
  analysisData: { data: ReadabilityResponseModel; status: string };
};
</script>
<style scoped lang="postcss">
.vdv-analysis__tab {
  @apply relative flex flex-col overflow-y-scroll bg-white shadow-lg overscroll-contain shadow-black px-0 pb-sm;
}
</style>

import { PageTypeHandle } from '~~/src/@types/type-handle';

export const getSchemaOrgMap: Partial<
  Record<
    PageTypeHandle,
    Promise<(pageEntry: any, options?: { url: string }) => any>
  >
> = {
  [PageTypeHandle.EVENT]: import('./getEventPageSchema').then((module) => {
    return module.default;
  }),
  [PageTypeHandle.POST]: import('./getPostPageSchema').then((module) => {
    return module.default;
  }),
  [PageTypeHandle.SERVICE]: import('./getServicePageSchema').then((module) => {
    return module.default;
  }),
  [PageTypeHandle.OFFICE]: import('./getOfficePageSchema').then((module) => {
    return module.default;
  }),
  [PageTypeHandle.OFFER]: import('./getOfferPageSchema').then((module) => {
    return module.default;
  }),
  [PageTypeHandle.PERSON]: import('./getPersonPageSchema').then((module) => {
    return module.default;
  }),
};

export default getSchemaOrgMap;

<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="relative flex flex-col gap-md p-sm mt-md"
    style="font-family: arial, sans-serif"
  >
    <div
      v-for="mode of ['desktop', 'mobile'] as ['desktop', 'mobile']"
      :key="mode"
    >
      <div
        :class="mode === 'desktop' ? 'w-[630px]' : 'w-[400px]'"
        class="bg-white border-gray-300 rounded-lg p-sm border-1"
      >
        <div
          :class="mode === 'desktop' ? 'w-[600px]' : 'w-[370px]'"
          class="flex flex-col"
        >
          <div
            class="flex"
            :class="mode === 'desktop' ? 'w-[410px]' : 'w-[370px]'"
            style="font-size: 14px; line-height: 18px; padding-bottom: 3px"
          >
            <span>https://www.vanderven.de</span>
            <span style="color: #5f6368" class="truncate">
              &nbsp;{{ '› '.concat(breadcrumbs[mode].join(' › ')) }}
            </span>
          </div>
          <div
            style="
              font-size: 20px;
              color: #1a0dab;
              line-height: 26px;
              margin-bottom: 3px;
            "
          >
            {{ title[mode] }}
          </div>
          <div style="font-size: 14px">
            <span style="color: #70757a">12 Sept 2022 — </span
            ><span v-html="description[mode]"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GooglePreview } from '@/@types/seoreviews';

const props = defineProps({
  data: {
    type: Object as PropType<GooglePreview>,
    required: true,
  },
});

const title = computed(() => {
  let titleDesktop = props.data.title;
  let titleMobile = props.data.title;
  if (titleDesktop.length > 60) {
    titleDesktop = titleDesktop.slice(0, 57).concat('...');
  }
  if (titleMobile.length > 70) {
    titleMobile = titleMobile.slice(0, 67).concat('...');
  }
  return { desktop: titleDesktop, mobile: titleMobile };
});

const description = computed(() => {
  let descriptionDesktop = props.data.description;
  let descriptionMobile = props.data.description;
  if (descriptionDesktop.length > 160) {
    descriptionDesktop = descriptionDesktop.slice(0, 157).concat('...');
  }
  if (descriptionMobile.length > 140) {
    descriptionMobile = descriptionMobile.slice(0, 137).concat('...');
  }
  if (props.data.keyword?.length > 0) {
    for (const word of props.data.keyword.split(' ')) {
      descriptionDesktop = descriptionDesktop.replace(
        new RegExp(`${word}(?!<\/b>)`, 'g'),
        ` <b>${word}</b> `,
      );
      descriptionDesktop = descriptionDesktop.replace(
        new RegExp(`${word.toLocaleLowerCase()}(?!<\/b>)`, 'g'),
        ` <b>${word.toLocaleLowerCase()}</b> `,
      );
    }
  }

  return { desktop: descriptionDesktop, mobile: descriptionMobile };
});

const breadcrumbs = computed(() => {
  const maxCrumbs = 2;
  const breadcrumbsDesktop = props.data.breadcrumbs;
  const breadcrumbsMobile = props.data.breadcrumbs;
  if (breadcrumbsDesktop.length > maxCrumbs) {
    for (let i = 0; i < breadcrumbsDesktop.length - maxCrumbs; i++) {
      breadcrumbsDesktop[i] = '...';
      breadcrumbsMobile[i] = '...';
    }
  }
  breadcrumbsDesktop.length;
  return { desktop: breadcrumbsDesktop, mobile: breadcrumbsMobile };
});
</script>

<style scoped lang="postcss"></style>

<template>
  <SeoTool
    v-if="isPreview"
    :meta-data="{ title: headTags.title, meta: headTags.meta, breadcrumbs }"
  />
</template>
<script setup lang="ts">
import { ETypeHandle } from '@/@types/type-handle';
import SeoTool from '../seotooling/seo-tool.vue';
import { UseStateKeys } from '@/useStateKeys';
import getSchemaOrgMap from './utils/pageSchema/dynamicImportMap';
import type {
  BasePageData,
  CMSPage,
  SubPage,
} from '~~/src/server/transformers/page/basePage';
import { useGetSeoTitleAppendix } from '@/composables/SEO/general';

const props = defineProps({
  pageEntry: {
    type: Object as PropType<BasePageData>,
    required: true,
  },
});

const host = import.meta.server
  ? useRequestHeaders(['host']).host
  : window.location.host.split(':')[0];

const router = useRouter();
const isPreview = useState(
  UseStateKeys.IS_PREVIEW,
  () =>
    router.currentRoute?.value?.query['x-craft-live-preview'] &&
    !router.currentRoute?.value?.query['disable-seo-tool'],
);

const breadcrumbs = getBreadcrumbs(props.pageEntry.temp_raw);
const headTags = generateHeadTags(props.pageEntry.temp_raw, host);

useHead(headTags);
useSchemaOrg([
  ...getBaseSchema(props.pageEntry.temp_raw),
  ...(await getPageSchema(props.pageEntry, router.currentRoute.value.path)),
  ...[
    defineBreadcrumb({
      itemListElement: breadcrumbs,
    }),
  ],
]);

// ------ Functions ------
async function getPageSchema(pageEntry: BasePageData, path: string) {
  if (!pageEntry) {
    return [];
  }

  const options = { url: path };

  const getSchema = await getSchemaOrgMap[pageEntry.typeHandle];
  if (getSchema) {
    const schema = getSchema(pageEntry, options);
    return schema;
  }
  return [];
}

function getBaseSchema(pageEntry: CMSPage) {
  const hasSeoMetaData = pageEntry?.seoMetaData?.length > 0;

  return [
    defineOrganization({
      name: 'van der Ven',
      logo: '/logo.png',
      sameAs: ['https://vanderven.de/ueber-uns'],
    }),
    defineWebSite({
      name: 'van der ven',
      description: getSeoDescription({
        seoDesc: pageEntry
          ? hasSeoMetaData
            ? pageEntry.seoMetaData[0].metaDescription
            : null
          : null,
        pageDesc: (pageEntry as any).entryShortDescription ?? null,
      }),
    }),
    defineWebPage({
      name: getSeoTitle({
        seoTitle: pageEntry
          ? hasSeoMetaData
            ? pageEntry.seoMetaData[0].metaTitle
            : null
          : null,
        pageTitle: pageEntry?.title ?? null,
      }),
    }),
  ];
}

function getBreadcrumbs(pageEntry: CMSPage) {
  let parentElement: string;
  if (!pageEntry) {
    return [];
  }

  if (
    pageEntry.typeHandle === ETypeHandle.PAGE ||
    pageEntry.typeHandle === ETypeHandle.LANDINGPAGE
  ) {
    parentElement = pageEntry.uri;
  } else {
    parentElement =
      (pageEntry as SubPage).treeParent.length > 0
        ? (pageEntry as SubPage).treeParent[0].uri
        : null;
  }

  if (parentElement) {
    const crumbs = parentElement.split('/').reduce((breadcrumbs, name, i) => {
      const item = `${
        breadcrumbs.length
          ? (breadcrumbs[i - 1]?.item ?? '')
          : import.meta.client
            ? location.origin
            : 'https://vanderven.de'
      }/${name}`;
      breadcrumbs.push({ name, item });
      return breadcrumbs;
    }, []);
    return crumbs;
  } else {
    return [];
  }
}

function generateHeadTags(pageEntry: CMSPage, host: string) {
  const hasSeoMetaData = pageEntry?.seoMetaData?.length > 0;

  const title = getSeoTitle({
    seoTitle: hasSeoMetaData ? pageEntry.seoMetaData[0].metaTitle : null,
    pageTitle: pageEntry?.title ?? null,
  });

  const description = getSeoDescription({
    seoDesc: hasSeoMetaData ? pageEntry.seoMetaData[0].metaDescription : null,
    pageDesc: (pageEntry as any)?.entryShortDescription ?? null,
    fallback: pageEntry?.title,
  });

  const previewImageUrl = pageEntry
    ? getSeoImage({
        seoImg: hasSeoMetaData
          ? pageEntry.seoMetaData[0].previewImage[0]?.url
          : null,
        titleImg:
          (pageEntry as any).assetsImage?.length > 0
            ? (pageEntry as any).assetsImage[0].image[0]?.url
            : null,
        fallback: '',
      })
    : '';

  const headTags = {
    title: title,
    meta: [
      {
        name: 'description',
        content: description ?? title,
      },
      {
        name: 'og:title',
        content: title,
      },
      {
        name: 'og:description',
        content: description ?? title,
      },
      {
        name: 'og:image',
        content: previewImageUrl as string,
      },
      {
        name: 'og:url',
        content: host + useRoute().fullPath,
      },
    ],
  };

  // Add robots meta tag if path ends with pattern(_ + 6 numbers) like /test/asdasd_123456
  if (/^.+(_\d{6})$/.test(router.currentRoute.value.path)) {
    headTags.meta.push({ name: 'robots', content: 'noindex,follow' });
  }

  return headTags;
}

function getSeoDescription(data: {
  seoDesc?: string;
  pageDesc?: string;
  fallback?: string;
}) {
  return data.seoDesc ?? data.pageDesc ?? data.fallback ?? '';
}

function getSeoTitle(data: {
  seoTitle?: string;
  pageTitle?: string;
  fallback?: string;
}) {
  let title =
    data.seoTitle ?? data.pageTitle ?? data.fallback ?? 'van der Ven-Dental';
  if (useSiteIdent() === SiteIdent.default) {
    const spacer = ' | ';
    if (title.length < 26) {
      title = title.concat(spacer).concat('vanderven.de - Ihr Dental-Depot');
    } else if (title.length < 45) {
      title = title.concat(spacer).concat('vanderven.de');
    }
    return title;
  } else {
    return title.concat(useGetSeoTitleAppendix(title.length));
  }
}

function getSeoImage(data: {
  seoImg?: string;
  titleImg?: string;
  fallback?: string;
}) {
  return data.seoImg ?? data.titleImg ?? data.fallback;
}
</script>

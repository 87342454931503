<template>
  <div class="relative grid grid-cols-4 gap-md p-sm">
    <AnalysisDataBlock :data="getOverviewData()"></AnalysisDataBlock>
    <AnalysisDataBlock :data="getWordCountData()"></AnalysisDataBlock>
    <AnalysisDataBlock :data="getRelatedKeywordsData()"></AnalysisDataBlock>
    <AnalysisDataBlock :data="getMetaTagData()"></AnalysisDataBlock>
    <AnalysisDataBlock :data="getHeadlineData()"></AnalysisDataBlock>
    <AnalysisDataBlock :data="getOnPageLinkData()"></AnalysisDataBlock>
    <AnalysisDataBlock :data="getTitleTagData()"></AnalysisDataBlock>
    <AnalysisDataBlock :data="getImageData()"></AnalysisDataBlock>
  </div>
</template>

<script setup lang="ts">
import type { ContentResponseModel } from '@/@types/seoreviews';
import AnalysisDataBlock from './analysis-data-block.vue';

const props = defineProps({
  contentAnalysis: {
    type: Object as PropType<ContentResponseModel>,
    required: true,
  },
});

function getOverviewData() {
  const obj = {
    headline: 'Gesamt',
    data: new Map(),
    rating: 'neutral',
    feedback: new Map(),
  };
  obj.data.set(
    'Wertung',
    `${props.contentAnalysis.Overview['Overall SEO score']}/100`,
  );
  obj.data.set(
    'Punkte',
    `${props.contentAnalysis.Overview['Earned SEO points'].toFixed(0)}/${
      props.contentAnalysis.Overview['Available SEO points']
    }`,
  );
  obj.data.set('Fehler', props.contentAnalysis.Overview.Summary.Errors);
  obj.data.set('Warnungen', props.contentAnalysis.Overview.Summary.Warnings);

  if (props.contentAnalysis.Overview['Overall SEO score'] > 80) {
    obj.rating = 'positive';
  } else if (props.contentAnalysis.Overview['Overall SEO score'] < 60) {
    obj.rating = 'negative';
  }

  //setFeedbackData(props.contentAnalysis['Overall SEO score'], obj.feedback);

  return obj;
}

function getHeadlineData() {
  const obj = {
    headline: 'Headlines',
    data: new Map(),
    rating: 'neutral',
    feedback: new Map(),
  };
  const seoScoreRatio = getSeoScore(
    props.contentAnalysis['Page Headings summary'],
  );
  obj.rating = seoScoreRatio.rating(seoScoreRatio.scoreRelative);
  obj.headline = obj.headline.concat(` (${seoScoreRatio.score})`);

  const headlines = [];
  for (let i = 1; i < 7; i++) {
    headlines.push(
      (props.contentAnalysis['Page Headings summary'] as any)[`H${i}`],
    );
  }
  obj.data.set('Headlines (H1-6)', headlines.join(' | '));
  obj.data.set(
    'Fokus-Keywords',
    props.contentAnalysis['Page Headings summary']['Focus keywords found'],
  );

  setFeedbackData(props.contentAnalysis['Page Headings summary'], obj.feedback);

  return obj;
}

function getTitleTagData() {
  const obj = {
    headline: 'Titel-Tag',
    data: new Map(),
    rating: 'neutral',
    feedback: new Map(),
  };
  const seoScoreRatio = getSeoScore(props.contentAnalysis['Title tag']);
  obj.rating = seoScoreRatio.rating(seoScoreRatio.scoreRelative);
  obj.headline = obj.headline.concat(` (${seoScoreRatio.score})`);

  obj.data.set(
    'Titel gefunden',
    props.contentAnalysis['Title tag']['Title found'],
  );
  obj.data.set(
    'Titel-Länge',
    props.contentAnalysis['Title tag']['Title length'],
  );
  obj.data.set(
    'Fokus-Keywords',
    props.contentAnalysis['Title tag']['Focus keywords found'],
  );

  setFeedbackData(props.contentAnalysis['Title tag'], obj.feedback);

  return obj;
}

function getMetaTagData() {
  const obj = {
    headline: 'Meta Beschr.',
    data: new Map(),
    rating: 'neutral',
    feedback: new Map(),
  };
  const seoScoreRatio = getSeoScore(props.contentAnalysis['Meta description']);
  obj.rating = seoScoreRatio.rating(seoScoreRatio.scoreRelative);
  obj.headline = obj.headline.concat(` (${seoScoreRatio.score})`);

  obj.data.set(
    'Beschreibungs-Länge',
    props.contentAnalysis['Meta description']['Meta description length'],
  );
  obj.data.set(
    'Keywords',
    props.contentAnalysis['Meta description']['Focus keywords found'],
  );

  setFeedbackData(props.contentAnalysis['Meta description'], obj.feedback);

  return obj;
}

function getWordCountData() {
  const obj = {
    headline: 'Inhalt',
    data: new Map(),
    rating: 'neutral',
    feedback: new Map(),
  };
  const seoScoreRatio = getSeoScore(props.contentAnalysis['Word count']);
  const seoScoreRatio_kwDensity = getSeoScore(
    props.contentAnalysis['Keyword density'],
  );
  obj.rating = seoScoreRatio.rating(
    (seoScoreRatio.scoreRelative + seoScoreRatio_kwDensity.scoreRelative) / 2,
  );
  //obj.headline = obj.headline.concat(
  //  ` (${(
  //    (seoScoreRatio.scoreRelative + seoScoreRatio_kwDensity.scoreRelative) /
  //    2
  //  ).toString()})`,
  //);

  const combinedScore = ` (${
    props.contentAnalysis['Keyword density']['SEO Score'] +
    props.contentAnalysis['Word count']['SEO Score']
  }/${
    props.contentAnalysis['Keyword density']['Max SEO score available'] +
    props.contentAnalysis['Word count']['Max SEO score available']
  })`;
  obj.headline = obj.headline.concat(combinedScore);

  obj.data.set(
    'Wörter',
    props.contentAnalysis['Word count']['Word count total'],
  );
  obj.data.set(
    'Anker-Wörter',
    props.contentAnalysis['Word count']['Anchor Percentage'] + '%',
  );
  obj.data.set(
    'Keyword-Dichte',
    props.contentAnalysis['Keyword density']['Keyword density'],
  );

  setFeedbackData(props.contentAnalysis['Keyword density'], obj.feedback);
  setFeedbackData(props.contentAnalysis['Word count'], obj.feedback);

  return obj;
}

function getOnPageLinkData() {
  const obj = {
    headline: 'Links',
    data: new Map(),
    rating: 'neutral',
    feedback: new Map(),
  };
  const seoScoreRatio = getSeoScore(
    props.contentAnalysis['On page links summary'],
  );
  obj.rating = seoScoreRatio.rating(seoScoreRatio.scoreRelative);
  obj.headline = obj.headline.concat(` (${seoScoreRatio.score})`);

  obj.data.set(
    'Links Gesamt',
    props.contentAnalysis['On page links summary']['Total links'],
  );
  obj.data.set(
    'Externe Links',
    props.contentAnalysis['On page links summary']['External links'],
  );
  obj.data.set(
    'Interne Links',
    props.contentAnalysis['On page links summary'].Internal,
  );

  setFeedbackData(props.contentAnalysis['On page links summary'], obj.feedback);

  return obj;
}

function getImageData() {
  const obj = {
    headline: 'Bilder',
    data: new Map(),
    rating: 'neutral',
    feedback: new Map(),
  };
  const seoScoreRatio = getSeoScore(props.contentAnalysis['Image analysis']);
  obj.rating = seoScoreRatio.rating(seoScoreRatio.scoreRelative);
  obj.headline = obj.headline.concat(` (${seoScoreRatio.score})`);

  obj.data.set(
    'Bilder Gesamt',
    props.contentAnalysis['Image analysis']['Number of images'],
  );
  obj.data.set(
    'Mit Keyword in Name',
    props.contentAnalysis['Image analysis']['Image name contains keyword'],
  );
  obj.data.set(
    'Mit Alt-Tag Keyword',
    props.contentAnalysis['Image analysis']['Image ALT tag contains keyword'],
  );

  setFeedbackData(props.contentAnalysis['Image analysis'], obj.feedback);

  return obj;
}

function getRelatedKeywordsData() {
  const obj = {
    headline: 'Extra Keywords',
    data: new Map(),
    rating: 'neutral',
    feedback: new Map(),
  };
  const seoScoreRatio = getSeoScore(props.contentAnalysis['Related keywords']);
  obj.rating = seoScoreRatio.rating(seoScoreRatio.scoreRelative);
  obj.headline = obj.headline.concat(` (${seoScoreRatio.score})`);

  obj.data.set(
    'Gefunden',
    props.contentAnalysis['Related keywords']['Related keywords found'],
  );
  obj.data.set(
    'Nicht Gefunden',
    props.contentAnalysis['Related keywords']['Related keywords not found'],
  );
  obj.data.set(
    'Semi | Exakt',
    `${props.contentAnalysis['Related keywords']['Intelligent match']} | ${props.contentAnalysis['Related keywords']['Exact match']}`,
  );

  setFeedbackData(props.contentAnalysis['Related keywords'], obj.feedback);

  return obj;
}

function getSeoScore(seoObj: any) {
  return {
    score: `${seoObj['SEO Score'].toFixed(0)}/${
      seoObj['Max SEO score available']
    }`,
    scoreRelative:
      parseFloat(
        (seoObj['SEO Score'] / seoObj['Max SEO score available']).toFixed(2),
      ) * 100,
    rating(scoreRelative: number) {
      if (scoreRelative < 60) {
        return 'negative';
      } else if (scoreRelative > 80) {
        return 'positive';
      } else {
        return 'neutral';
      }
    },
  };
}

function setFeedbackData(obj: any, dataMap: Map<any, any>) {
  const feedback = obj['Feedback details'] as Record<string, any>;
  if (feedback) {
    for (const key in feedback) {
      dataMap.set(key, feedback[key]);
    }
  }
  return;
}
</script>

<style scoped lang="postcss"></style>

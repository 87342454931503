<template>
  <h2 class="px-sm">Readability/Lesbarkeit Analyse</h2>
  <div class="relative grid grid-cols-3 col-span-auto gap-md p-sm">
    <div v-for="(value, key) in props.readbilityAnalysis" :key="key">
      <div v-if="typeof value === 'object'">
        <h3>{{ key }}:</h3>
        <div v-for="(entry, keyEntry) in value" :key="keyEntry">
          <h5>{{ keyEntry }}:</h5>
          {{ entry }}
        </div>
      </div>
      <div v-else>
        <h3>{{ key }}:</h3>
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ReadabilityResponseModel } from '@/@types/seoreviews';

const props = defineProps({
  readbilityAnalysis: {
    type: Object as PropType<ReadabilityResponseModel['data']>,
    required: true,
  },
});
</script>

<style scoped lang="postcss"></style>
